import 'jquery';
import 'bootstrap'
import "../assets/images/nll-logo.png";

window.addEventListener('scroll', function () {
    var element = document.querySelector('.navbar');
    var position = element.getBoundingClientRect();


    // Checks whether the element is in the viewport
    if (position.top <= window.innerHeight && position.bottom >= 0) {
        element.classList.add('active');
    } else {
        element.classList.remove('active');
    }

    // Check if user scrolled back to the top of the screen
    if (window.scrollY === 0) {
        element.classList.remove('active');
    }
});
